
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class Login extends Vue {
    public user = {
      email: '',
      password: '',
      username: ''
    }

    // private user = new User('', '')
    public message = ''

    public options = {
      isLoggingIn: true,
      shouldStayLoggedIn: true,
      isLoading: false
    }

    $store: any
    $router: any

    public async handleLogin () {
      this.options.isLoading = true
      if (this.user.email && this.user.password) {
        try {
          await this.$store.dispatch('auth/login', this.user)
          console.log('loading')
          await this.$store.dispatch('initialState', this)
          this.$router.push('/')
        } catch (error: any) {
          this.options.isLoading = false
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      }
      this.options.isLoading = false
    }

    public handleSignup () {
      this.options.isLoading = true
      if (this.user.email && this.user.password && this.user.username) {
        this.$store.dispatch('auth/register', this.user).then(
          () => {
            this.user.email = ''
            this.user.password = ''
            this.user.username = ''
            this.options.isLoggingIn = true
          },
          (error: any) => {
            this.options.isLoading = false
            this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
          }
        )
      }
      this.options.isLoading = false
    }
}
