
import VueTradingView from 'vue-trading-view'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    VueTradingView: VueTradingView
  }
})
export default class Charts extends Vue {
  public trading_view_options = {
    symbol: window.location.href.split('?')[1],
    interval: '5m',
    timezone: 'Etc/UTC',
    width: '100%',
    height: '800px',
    theme: 'light',
    style: '1',
    locale: 'en',
    range: 'YTD',
    // eslint-disable-next-line @typescript-eslint/camelcase
    allow_symbol_change: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    hide_side_toolbar: false,
    withdateranges: true,
    studies: [
      // 'MACD@tv-basicstudies',
      // 'Stochastic@tv-basicstudies',
      // 'StochasticRSI@tv-basicstudies',
      // 'PivotPointsHighLow@tv-basicstudies',
      // 'PivotPointsStandard@tv-basicstudies',
      // 'ATR@tv-basicstudies',
      'Supertrend@tv-basicstudies',
      // 'AwesomeOscillator@tv-basicstudies',
      // 'HV@tv-basicstudies',
      // 'VSTOP@tv-basicstudies',
      'Volume@tv-basicstudies'
    ]
  }

  public close () {
    window.close()
  }
}
